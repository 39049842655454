<template>
  <UiPopup
    :model-value="modelValue"
    title="Change user expertise"
    primary-button-text="Save"
    secondary-button-text="Cancel"
    size="small"
    :loading="isChanging"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="submit"
  >
    <div class="flex flex-col gap-4">
      <p>
        Select one or more areas of expertise that <br />
        the user is responsible for.
      </p>

      <UiLoader v-if="isLoading" />

      <div v-else-if="expertiseLevels.length" class="flex flex-col gap-2">
        <div
          v-for="level in expertiseLevels"
          :key="level.id"
          class="min-h-12 cursor-pointer select-none rounded-xl border border-primary-20 px-4 py-3 transition-colors hover:border-primary-30 hover:bg-primary-05"
          :class="{ '!border-primary-50 bg-primary-05': level.checked }"
          @click="level.checked = !level.checked"
        >
          <UiInputCheckbox v-model="level.checked" :name="level.id" :label="level.text" />
        </div>
      </div>

      <Transition name="fade" mode="out-in">
        <div
          v-if="useGetFieldErrors(v$, ['selectedExpertiseIds'])"
          class="text-subhead-4 rounded-lg bg-secondary-10 p-3 text-additional-3-100"
        >
          {{ useGetFieldErrors(v$, ['selectedExpertiseIds']) }}
        </div>
      </Transition>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { DICTIONARIES } from '~/constants'
import { useUiStore } from '~/store/ui'
import type { InputItem, User } from '~/types'

type Props = {
  modelValue: boolean
  user: User
}

const props = defineProps<Props>()

const emits = defineEmits(['update:modelValue', 'input'])

const uiStore = useUiStore()

const expertiseLevels = ref<
  (InputItem & {
    checked: boolean
  })[]
>([])

const selectedExpertiseIds = computed(() =>
  expertiseLevels.value.filter((level) => level.checked).map((level) => level.value)
)

const rules = computed(() => ({
  selectedExpertiseIds: {
    required: helpers.withMessage('Oops! Agent must have at least one area of expertise.', required),
  },
}))

const v$ = useVuelidate(
  rules,
  {
    selectedExpertiseIds,
  },
  {
    $autoDirty: true,
  }
)

const isLoading = ref(false)

const getExpertiseLevels = async () => {
  try {
    isLoading.value = true

    const data = await useDictionary(DICTIONARIES.EXPERTISE_LEVELS)

    expertiseLevels.value = data.map((level: InputItem) => ({
      ...level,
      checked: props.user.expertises.map(({ id }) => id).includes(level.value),
    }))
  } catch {
    uiStore.showSnackBanner('Failed to get expertise levels, please try again later', 'error')
  } finally {
    isLoading.value = false
  }
}

const isChanging = ref(false)

const submit = async () => {
  const isValid = await v$.value.$validate()

  if (!isValid) return

  try {
    isChanging.value = true

    await useEditUser(Number(props.user.id), {
      ...props.user,
      expertise_ids: selectedExpertiseIds.value,
    })

    uiStore.showSnackBanner('User expertise successfully changed', 'success')

    emits('update:modelValue', false)
    emits('input', selectedExpertiseIds.value)
  } catch {
    uiStore.showSnackBanner('Failed to change user expertise, please try again later', 'error')
  } finally {
    isChanging.value = false
  }
}

onMounted(() => {
  getExpertiseLevels()
})
</script>

<style scoped></style>
